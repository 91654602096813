import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { RetailerItemsService } from './services';
import { RetailerItemsState } from './store';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([RetailerItemsState])],
  providers: [RetailerItemsService],
})
export class RetailerItemsModule {}
