import { Currency } from '@supy.api/dictionaries';

import { LocalizedData, LocalizedSkeletonObjectType, PreferredType, ViewBranch } from '@supy/common';

export enum RetailerItemState {
  available = 'available',
  deleted = 'deleted',
}

export type RetailerItemId = string;

export class RetailerItem {
  readonly id: RetailerItemId;
  readonly supplierId: string;
  readonly retailerId: string;
  readonly catalogItemId?: string;
  readonly branchesIds?: string[];
  readonly name: LocalizedData;
  readonly currency: Currency;
  readonly price?: number;
  readonly unit?: string;
  readonly description?: string;
  readonly state?: RetailerItemState;
  readonly itemCode?: string = '';
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly 'name.en'?: never;
  readonly 'name.ar'?: never;
  preferredType?: PreferredType;
  readonly category?: LocalizedSkeletonObjectType;

  constructor(retailerItem: RetailerItem) {
    Object.assign(this, retailerItem);
  }
}

export class RetailerItemWithBranches extends RetailerItem {
  readonly branches?: ViewBranch[];
  constructor(retailerItem: RetailerItemWithBranches) {
    super(retailerItem);
  }
}
