import { catchError, EMPTY, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { RetailerItem, UploadRetailerItemsResponse } from '../../core';
import { RetailerItemsService } from '../../services';
import {
  RetailerItemsCreateItem,
  RetailerItemsDeleteAllItems,
  RetailerItemsDeleteItem,
  RetailerItemsDeleteItems,
  RetailerItemsGetItems,
  RetailerItemsUpdateItem,
  RetailerItemsUpdateItems,
  RetailerItemsUploadItems,
} from '../actions';

export interface RetailerItemsStateModel {
  readonly items: RetailerItem[];
  readonly loading: boolean;
  readonly error?: Error;
  readonly uploadRetailerItemsResponse: UploadRetailerItemsResponse;
}

export const RETAILER_ITEM_STATE_TOKEN = new StateToken<RetailerItemsStateModel>('RetailerItemsState');

@State<RetailerItemsStateModel>({
  name: RETAILER_ITEM_STATE_TOKEN,
})
@Injectable()
export class RetailerItemsState {
  constructor(private readonly retailerItemsService: RetailerItemsService) {}

  static item(itemId: string) {
    return createSelector([RETAILER_ITEM_STATE_TOKEN], (state: RetailerItemsStateModel) => {
      return state.items?.find(({ id }) => id === itemId);
    });
  }

  @Selector()
  static getRetailerItems(state: RetailerItemsStateModel) {
    return state.items;
  }

  @Selector()
  static uploadRetailerItemsResponse(state: RetailerItemsStateModel) {
    return state.uploadRetailerItemsResponse;
  }

  @Action(RetailerItemsGetItems)
  getRetailerItems({ patchState }: StateContext<RetailerItemsStateModel>, { query }: RetailerItemsGetItems) {
    patchState({ loading: true });

    return this.retailerItemsService.getRetailerItems(query).pipe(
      tap(({ data: items }) => patchState({ loading: false, items })),
      catchError((error: Error) => {
        patchState({ loading: false, error });

        return EMPTY;
      }),
    );
  }

  @Action(RetailerItemsCreateItem)
  createRetailerItem({ patchState }: StateContext<RetailerItemsStateModel>, { item }: RetailerItemsCreateItem) {
    patchState({ loading: true });

    return this.retailerItemsService.createRetailerItem(item).pipe(
      tap(() => patchState({ loading: false })),
      catchError((error: Error) => {
        patchState({ loading: false, error });

        return EMPTY;
      }),
    );
  }

  @Action(RetailerItemsUploadItems)
  uploadRetailerItems({ patchState }: StateContext<RetailerItemsStateModel>, { payload }: RetailerItemsUploadItems) {
    return this.retailerItemsService
      .uploadRetailerItemsBff(payload)
      .pipe(tap(uploadRetailerItemsResponse => patchState({ uploadRetailerItemsResponse })));
  }

  @Action(RetailerItemsUpdateItems)
  updateRetailerItems({ patchState }: StateContext<RetailerItemsStateModel>, { items }: RetailerItemsUpdateItems) {
    patchState({ loading: true });

    return this.retailerItemsService.updateRetailerItems(items).pipe(
      tap(() => patchState({ loading: false })),
      catchError((error: Error) => {
        patchState({ loading: false, error });

        return EMPTY;
      }),
    );
  }

  @Action(RetailerItemsUpdateItem)
  updateRetailerItem({ patchState }: StateContext<RetailerItemsStateModel>, { item, id }: RetailerItemsUpdateItem) {
    patchState({ loading: true });

    return this.retailerItemsService.updateRetailerItem(item, id).pipe(
      tap(() => patchState({ loading: false })),
      catchError((error: Error) => {
        patchState({ loading: false, error });

        return EMPTY;
      }),
    );
  }

  @Action(RetailerItemsDeleteItem)
  deleteRetailerItem({ patchState }: StateContext<RetailerItemsStateModel>, { id }: RetailerItemsDeleteItem) {
    patchState({ loading: true });

    return this.retailerItemsService.deleteRetailerItem(id).pipe(
      tap(() => patchState({ loading: false })),
      catchError((error: Error) => {
        patchState({ loading: false, error });

        return EMPTY;
      }),
    );
  }

  @Action(RetailerItemsDeleteItems)
  deleteRetailerItems({ patchState }: StateContext<RetailerItemsStateModel>, { ids }: RetailerItemsDeleteItems) {
    patchState({ loading: true });

    return this.retailerItemsService.deleteRetailerItems(ids).pipe(
      tap(() => patchState({ loading: false })),
      catchError((error: Error) => {
        patchState({ loading: false, error });

        return EMPTY;
      }),
    );
  }

  @Action(RetailerItemsDeleteAllItems)
  deleteAllRetailerItems(
    { patchState }: StateContext<RetailerItemsStateModel>,
    { channelId }: RetailerItemsDeleteAllItems,
  ) {
    patchState({ loading: true });

    return this.retailerItemsService.deleteAllRetailerItems(channelId).pipe(
      tap(() => patchState({ loading: false })),
      catchError((error: Error) => {
        patchState({ loading: false, error });

        return EMPTY;
      }),
    );
  }
}
