import { Query } from '@supy/common';

import {
  CreateRetailerItemRequest,
  RetailerItem,
  UpdateRetailerItemRequest,
  UpdateRetailerItemsRequest,
  UploadRetailerItemsRequest,
} from '../../core';

export class RetailerItemsGetItems {
  static readonly type = '[RetailerItems] GetItems';
  constructor(public query: Query<RetailerItem>) {}
}

export class RetailerItemsCreateItem {
  static readonly type = '[RetailerItems] CreateItem';
  constructor(public item: CreateRetailerItemRequest) {}
}

export class RetailerItemsUploadItems {
  static readonly type = '[RetailerItems] CreateItems';
  constructor(public payload: UploadRetailerItemsRequest) {}
}

export class RetailerItemsUpdateItem {
  static readonly type = '[RetailerItems] UpdateItem';
  constructor(
    public id: string,
    public item: UpdateRetailerItemRequest,
  ) {}
}

export class RetailerItemsUpdateItems {
  static readonly type = '[RetailerItems] UpdateItems';
  constructor(public items: UpdateRetailerItemsRequest) {}
}

export class RetailerItemsDeleteItem {
  static readonly type = '[RetailerItems] DeleteItem';
  constructor(public id: string) {}
}

export class RetailerItemsDeleteItems {
  static readonly type = '[RetailerItems] DeleteItems';
  constructor(public ids: string[]) {}
}

export class RetailerItemsDeleteAllItems {
  static readonly type = '[RetailerItems] DeleteAllItems';
  constructor(public channelId: string) {}
}
