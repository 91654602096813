import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { RETAILER_ITEMS_BFF_URI, RETAILER_ITEMS_URI } from '../config';
import {
  CreateRetailerItemRequest,
  CreateRetailerItemResponse,
  CreateRetailerItemsRequest,
  CreateRetailerItemsResponse,
  RetailerItem,
  RetailerItemId,
  RetailerItemState,
  UpdateRetailerItemRequest,
  UpdateRetailerItemsRequest,
  UploadRetailerItemsRequest,
  UploadRetailerItemsResponse,
} from '../core';

@Injectable()
export class RetailerItemsService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(RETAILER_ITEMS_URI) private readonly uri: string,
    @Inject(RETAILER_ITEMS_BFF_URI) private readonly uriBff: string,
  ) {}

  createRetailerItems(body: CreateRetailerItemsRequest): Observable<CreateRetailerItemsResponse> {
    return this.httpClient.post<CreateRetailerItemsResponse>(`${this.uri}/batch`, body);
  }

  createRetailerItem(body: CreateRetailerItemRequest): Observable<CreateRetailerItemResponse> {
    return this.httpClient.post<CreateRetailerItemResponse>(this.uri, body);
  }

  updateRetailerItem(body: UpdateRetailerItemRequest, itemId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${itemId}`, body);
  }

  updateRetailerItems(body: UpdateRetailerItemsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/batch`, body);
  }

  deleteRetailerItem(id: RetailerItemId): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${id}`);
  }

  deleteRetailerItems(retailerItemIds: RetailerItemId[]): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/batch`, { body: retailerItemIds });
  }

  deleteAllRetailerItems(channelId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/batch/${channelId}`);
  }

  getRetailerItems(query: Query<RetailerItem>): Observable<IQueryResponse<RetailerItem>> {
    query.filtering.setFilter({ by: 'state', match: RetailerItemState.available, op: 'eq' });

    return this.httpClient.get<IQueryResponse<RetailerItem>>(this.uri, { params: query.toQueryParams() });
  }

  uploadRetailerItemsBff(body: UploadRetailerItemsRequest): Observable<UploadRetailerItemsResponse> {
    return this.httpClient.post<UploadRetailerItemsResponse>(`${this.uriBff}/batch`, body);
  }
}
